<template>
  <div>
    <WsUpdate
      class="pb-60"
      ref="WsCreate"
      :id="_id"
      :modelName="model.tulpa_page.modelName"
      :label="model.tulpa_page.label"
      :fields="model.tulpa_page.fields"
      @input="$_onInput($event)"
      :value="state"
      :emitSubmit="true"
      :emitInput="true"
      :hasBreadcrumbs="false"
      :primary="pageSetting.primary"
      :secondary="_pageSettingSecondary"
      titleKey="name"
      @submit="$_onSubmit()"
    >
    </WsUpdate>
  </div>
</template>

<script>
import tulpa_page from "@/__vue2stone_cms/models/tulpa_page";
import ServiceTulpaPage from "@/__vue2stone_cms/service/api/v1/tulpa_page";
import S_App_Auth from "@/__vue2stone_cms/service/app/auth";
import S_App_State from "@/__vue2stone_cms/service/app/state";
export default {
  metaInfo() {
    return {
      title: `${this.$t("內容編輯")}`,
    };
  },

  data: () => ({
    pageSetting: {
      primary: [
        {
          type: "stateCard",
          floors: [
            {
              title: "頁面SEO設定",
              titleInLocale: true,
              fields: [
                "route",
                "title",
                "og_image",
                "canonical_url",
                "description",
                "tags",
              ],
            },
          ],
        },
      ],
    },
    model: {
      tulpa_page: tulpa_page,
    },
    state: {},
  }),

  methods: {
    $_onInput($event) {
      this.state = $event;
    },
    $_onTulpaSectionsInput($event) {
      if (this.state.tulpa_page_template) {
        this.state.content = $event;
      } else {
        this.state.content = $event;
      }
    },
    async $_onSubmit() {
      const postData = S_App_State.getFormatedStates(
        this.model.tulpa_page.fields,
        this.state,
        true
      );
      const _content = {};
      const _tulpa_sections = [];
      for (const key in this.state.content) {
        const contentItem = this.state.content[key];
        _content[key] = {
          id: contentItem.id,
          content: contentItem.content,
        };
        _tulpa_sections.push(contentItem.id);
      }
      postData.content = btoa(
        unescape(encodeURIComponent(JSON.stringify(_content)))
      );
      if (!this.state.tulpa_page_template) {
        postData.tulpa_sections = _tulpa_sections;
      }
      try {
        this.$refs.WsCreate.loadingStart();
        await ServiceTulpaPage.patch(this._id, postData);
        this.$router.push("/tulpa_page");
      } catch (error) {
        console.error(error);
        alert(this.$t("make_sure_route_not_repeat"));
      } finally {
        this.$refs.WsCreate.loadingStop();
      }
    },
  },

  computed: {
    _pageSettingSecondary() {
      const fields = [
        "is_active",
        "name",
        "tulpa_page_template",
        "tulpa_cross_items",
        "remark",
      ];
      if (S_App_Auth.hasScope(["boss"])) {
        if (this.$config.wsmodule.country_code) {
          fields.push("country_code");
        }
        if (this.$config.auth.admin_group) {
          if (this.$config.auth.admin_blur) {
            fields.push("cmser_groups");
          } else {
            fields.push("admin_groups");
          }
        }
      }
      return [
        {
          type: "stateCard",
          floors: [
            {
              title: "頁面設定",
              titleInLocale: true,
              fields: fields,
            },
          ],
        },
      ];
    },
    _sectionContent() {
      if (this.state.tulpa_page_template) {
        const _sectionContent = {};
        const _tulpaSections = this.state.tulpa_page_template.tulpa_sections;
        for (const contentKey in this.state.tulpa_page_template.content) {
          const contentItem =
            this.state.tulpa_page_template.content[contentKey];
          const _tulpaSection = _tulpaSections.find((e) => {
            return e.id == contentItem.id;
          });
          const _defaultContent = {};
          for (let key in _tulpaSection.content) {
            const _tulpaContentItem = contentItem.content[key];
            const _sectionContentItem = _tulpaSection.content[key];
            if (_tulpaContentItem == null || _tulpaContentItem.length === 0) {
              _defaultContent[key] = _sectionContentItem;
            } else {
              _defaultContent[key] = _tulpaContentItem;
            }
          }
          _sectionContent[contentKey] = {
            id: contentItem.id,
            content: this.state.content[contentKey]
              ? this.state.content[contentKey].content
              : {},
            name: _tulpaSection.name,
            fields: _tulpaSection.fields,
            defaultContent: _defaultContent,
          };
        }
        return _sectionContent;
      } else {
        return this.state.content;
      }
    },
    _amountEditable() {
      if (this.state.tulpa_page_template) {
        return false;
      } else {
        return true;
      }
    },
    _id() {
      return this.$route.params.id;
    },
  },
};
</script>

<style>
</style>